// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// normal QA
// export const environment = {
//   production: false,
//   BASE_URL: 'http://qa4.scoretrivia.com:54080/#/',
//   BASE_API_URL: 'http://qa4.scoretrivia.com:54800/',
//   WS_URL: 'ws://qa4.scoretrivia.com:54810/games/',
//   RECONNECT_INTERVAL: 10,
//   domain: 'http://localhost:4200/#/host',
//   FB_APP_ID: '2087523328024856',
//   FB_APP_SECRET: 'aee49711f4fb5a2cbc831a8b83bb1a40'
// };

//QA3
/*export const environment = {
  production: false,
  BASE_URL: 'http://qa3.scoretrivia.com:53443/#/',
  BASE_API_URL: 'http://qa3.scoretrivia.com:53800/',
  WS_URL: 'ws://qa3.scoretrivia.com:53810/games/',
  domain: 'http://localhost:4200/#/host',
  FB_APP_ID: '2087523328024856',
  FB_APP_SECRET: 'aee49711f4fb5a2cbc831a8b83bb1a40'
};*/

export const environment = {
  production: false,
  BASE_URL: 'https://dev.trivtrak.com/#/',
  BASE_API_URL: 'https://api-dev.trivtrak.com:8000/',
  domain: 'https://dev.trivtrak.com/#/host/',
  WS_URL: 'wss://wss-dev.trivtrak.com:8001/games/',
  RECONNECT_INTERVAL: 10,
  FB_APP_ID: '2867204136641313',
  FB_APP_SECRET: '8a31ed46b29e39e9d620881e91368e97'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
